import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../Include/Header';
import Sidebar from '../../Include/Sidebar';

const PurchaseReturn = () => {

  const navigate = useNavigate();
  // const [totalItems, setTotalItems] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [search, setSearch] = useState("");
  // const [limit, setlimit] = useState(10);
  // const [disable, setDisable] = useState(true);
  // const [entity, setEntity] = useState([]);


  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }

    // if (disable === true) {
    //   setEntity([]);

    // }
  }, [navigate]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

    </div>
  )
}

export default PurchaseReturn
