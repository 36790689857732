import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Main = () => {

  const navigate = useNavigate();

  return (
    <div className="main-wrapper login-body">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-6 login-wrap">
            <div className="login-sec">
              <div className="log-img">
                <img className="img-fluid" src="/assets/img/login-img.png" alt="Logo" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 login-wrap-bg">
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <div className="account-logo">
                      <Link to="/"><img src="/assets/img/Logovishal.svg" alt="account-logo" /></Link>
                    </div>
                    <h2>Vishal Login</h2>
                    <form>
                      <div className="input-block login-btn">
                        <button className="btn-block  btn btn-primary" type="button" onClick={() => navigate('/admin/login')}>Admin Login</button>
                        <button className="btn-block  btn btn-primary mt-4" type="button" onClick={() => window.open('https://products.addonwebtech.com/user/login', '_blank')}>Payroll User Login</button>
                        <button className="btn-block  btn btn-primary mt-4" type="button" onClick={() => navigate('/user/login')}>Store User Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main