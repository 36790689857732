import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../Include/Header';
import Sidebar from '../Include/Sidebar';
import moment from 'moment';
import { FileText } from 'lucide-react';

const ViewRequest = () => {
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const data = location.state;
    console.log(data, '@@');

    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/user/project-store/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item">
                                        <Link to="/user/project-store/item-request-management">Request List</Link>
                                    </li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item active">View Request</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-12">
                                        <div className="form-heading">
                                            <h4>View Request Details</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {[
                                            { label: 'Request No.', value: data?.requestNo },
                                            { label: 'Project', value: data?.project?.name },
                                            { label: 'Project Location', value: data?.storeLocation === 1 ? 'Facility-1' : data?.storeLocation === 2 ? 'Facility-2' : data?.storeLocation === 3 ? 'Facility-3' : '' },
                                            { label: 'Request Date', value: moment(data?.requestDate).format('YYYY-MM-DD') },
                                            { label: 'Material PO No.', value: data?.material_po_no },
                                            { label: 'Department', value: data?.department?.name },
                                            { label: 'Approved By', value: data?.approvedBy?.name },
                                            { label: 'Prepared By', value: data?.preparedBy?.user_name },
                                        ].map(({ label, value }) => (
                                            <div key={label} className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label>{label}</label>
                                                    <input className="form-control" value={value} readOnly />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-12">
                                        <div className="form-heading">
                                            <h4>View Section Details</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {[
                                            { label: 'Section Details', value: data?.itemName?.name },
                                            { label: 'Unit', value: data?.itemName?.unit?.name },
                                            { label: 'M Code', value: data?.mcode },
                                            { label: 'Material Grade', value: data?.itemName.material_grade },
                                            { label: 'Quantity', value: data?.quantity },
                                        ].map(({ label, value }) => (
                                            <div key={label} className="col-12 col-md-3 col-xl-3">
                                                <div className="input-block local-forms">
                                                    <label>{label}</label>
                                                    <input className="form-control" value={value} readOnly />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="col-12 col-md-3 col-xl-3">
                                            <div className="input-block local-forms">
                                                <p className='m-0' style={{ fontSize: "12px" }}>Store</p>
                                                {data?.store_type === 1 ? (
                                                    <span className='custom-badge status-purple'>Main Store</span>
                                                ) : (
                                                    <span className='custom-badge status-purple'>Project Store</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-block local-forms">
                                                <label>Remark</label>
                                                <textarea className="form-control" value={data?.remarks} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {data?.drawing_id !== null ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12">
                                            <div className="form-heading">
                                                <h4>View Drawing Details</h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {[
                                                { label: 'Master Updation Date', value: moment(data?.drawing_id?.master_updation_date).format('YYYY-MM-DD') },
                                                { label: 'Drawing No.', value: data?.drawing_id?.drawing_no },
                                                { label: 'Drawing Receive Date', value: moment(data?.drawing_id?.draw_receive_date).format('YYYY-MM-DD') },
                                                { label: 'Unit', value: data?.drawing_id?.unit },
                                                { label: 'REV', value: data?.drawing_id?.rev },
                                                { label: 'Sheet No.', value: data?.drawing_id?.sheet_no },
                                                { label: 'Assembly No.', value: data?.drawing_id?.assembly_no },
                                                { label: 'Assembly Quantity', value: data?.drawing_id?.assembly_quantity },
                                                { label: 'Issued Date', value: moment(data?.drawing_id?.issued_date).format('YYYY-MM-DD') },
                                                { label: 'Issued To', value: data?.drawing_id?.issued_person?.name },
                                            ].map(({ label, value }) => (
                                                <div key={label} className="col-12 col-md-4 col-xl-4">
                                                    <div className="input-block local-forms">
                                                        <label>{label}</label>
                                                        <input className="form-control" value={value} readOnly />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='row'>
                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <p className='m-0' style={{ fontSize: "12px" }}>Status</p>
                                                    <span className={`custom-badge ${data?.drawing_id?.status === 1 ? 'status-orange' :
                                                        data?.drawing_id?.status === 2 ? 'status-green' : ''}`}>
                                                        {data?.drawing_id?.status === 1 ? 'Pending' : data?.drawing_id?.status === 2 ? 'Completed' : ''}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-xl-4">
                                                <a href={data?.drawing_id?.drawing_pdf} className='d-flex' target='_blank' rel="noreferrer" style={{ cursor: "pointer" }}>
                                                    <img src='/assets/img/pdflogo.png' /> <p>val{data?.drawing_id?.drawing_pdf_name}</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table border-0 mb-0 custom-table table-striped comman-table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr.</th>
                                                        <th>Section Details</th>
                                                        <th>Grid No.</th>
                                                        <th>Item No.</th>
                                                        <th>Qty.</th>
                                                        <th>Length(mm)</th>
                                                        <th>Width(mm)</th>
                                                        <th>Item Weight(kg)	</th>
                                                        <th>Assem. Weight(kg)</th>
                                                        <th>ASM(sqm)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.drawing_id?.items?.map((elem, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{elem?.itemName?.name}</td>
                                                            <td>{elem?.grid_no}</td>
                                                            <td>{elem?.item_no}</td>
                                                            <td>{elem?.quantity}</td>
                                                            <td>{elem?.item_length}</td>
                                                            <td>{elem?.item_width}</td>
                                                            <td>{elem?.item_weight}</td>
                                                            <td>{elem?.assembly_weight}</td>
                                                            <td>{elem?.assembly_surface_area}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ViewRequest;
