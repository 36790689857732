import React from 'react'
import Admin from './Routes/Admin'
import Home from './Routes/Home'
import Store from './Routes/Store'
import ProductStore from './Routes/ProductStore'
import Index from './Routes/erp/Index'
import UsersRoute from './Routes/Users/UsersRoute'


const App = () => {
  return (
    <>
      <Home />
      <Store />
      <ProductStore />
      <Index />
      <UsersRoute />
      <Admin />
    </>
  )
}

export default App