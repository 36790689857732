import React, { useEffect, useState, useMemo } from 'react'
import { P_STORE, V_URL } from '../../../BaseUrl';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Include/Header';
import Sidebar from '../Include/Sidebar';
import toast from 'react-hot-toast';
import DropDown from '../../../Components/DropDown';
import { Pagination, Search } from '../Table';
import Footer from '../Include/Footer';
import axios from 'axios';
import Loader from '../Include/Loader';
import moment from 'moment';
import { HandCoins, X } from 'lucide-react';
import RequestModal from '../../../Components/Request/RequestModal';
import { useDispatch, useSelector } from 'react-redux';
import { getItemStock } from '../../../Store/Store/Stock/getStock';
import { getStoreAuthPerson } from '../../../Store/Store/StoreMaster/AuthPerson/AuthPerson';
import { getParty } from '../../../Store/Store/Party/Party';

const Request = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);
    const [entity, setEntity] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState(null)

    useEffect(() => {
        // if (localStorage.getItem('PAY_USER_TOKEN') === null) {
        //     navigate("/user/login");
        // } else if (localStorage.getItem('VI_PRO') !== `${P_STORE}`) {
        //     toast.error('Access Denied. You do not have permission to view this product. Please contact your administrator for assistance.')
        //     navigate("/user/login");
        // }
        if (disable === true) {
            setEntity([]);
            getRequest();
        }
    }, [navigate, disable]);

    useEffect(() => {
        dispatch(getItemStock({ storeType: "" }))
        dispatch(getStoreAuthPerson())
        dispatch(getParty({ storeType: '' }))
    }, [dispatch]);

    const stockData = useSelector((state) => state?.getItemStock?.user?.data);
    const authPerson = useSelector((state) => state?.getStoreAuthPerson?.user?.data);
    const partyData = useSelector((state) => state?.getParty?.user?.data);

    const commentsData = useMemo(() => {
        let computedComments = entity;
        if (search) {
            computedComments = computedComments.filter(
                (request) =>
                    request.requestNo?.toString().toLowerCase().includes(search.toLowerCase()) ||
                    request.itemName?.name.toLowerCase().includes(search.toLowerCase()) ||
                    request.itemName?.unit?.name.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);
        return computedComments.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, entity, search, limit, totalItems]);

    const getRequest = () => {
        const myurl = `${V_URL}/user/get-store-request`;
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('tag', '1');
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then((response) => {
            console.log(response.data, '@@');
            if (response.data.success === true) {
                const data = response.data?.data;
                const filteredData = data?.filter(e => e?.project?._id === localStorage.getItem('U_PROJECT_ID') && e?.requestStatus === (2 || 4));
                setEntity(filteredData);
                setDisable(false);
            }
        }).catch((error) => {
            console.log(error, '!!');
            setDisable(false);
        });
    }

    const handleClose = () => setShow(false);
    const handleReceive = (elem) => {
        setSelectedData(elem)
        setShow(true)
    }

    const handleSave = (data) => {
        // console.log(data, '@@');
        console.log('post')
        const myurl = `${V_URL}/user/manage-purchase-offer`;
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('requestId', data?.req_id);
        bodyFormData.append('offeredQty', data?.offer_qty);
        bodyFormData.append('transactionId', data?.transaction_id);
        bodyFormData.append('offerNos', data?.offer_nos);
        bodyFormData.append('offerLength', data?.offer_length);
        bodyFormData.append('lotNo', data?.lot_no);
        bodyFormData.append('balance_qty', data?.balance_qty);
        bodyFormData.append('remarks', data?.remark);
        bodyFormData.append('offeredBy', localStorage.getItem('PAY_USER_ID'));
        bodyFormData.append('offer_uom', data?.offer_uom);
        bodyFormData.append('received_date', data?.receive_date);
        bodyFormData.append('challan_qty', data?.challan_qty);
        bodyFormData.append('offerWidth', data?.offer_width)

        axios({
            method: 'post',
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
        }).then((response) => {
            if (response.data.success === true) {
                toast.success(response.data.message);
                setShow(false);
                setDisable(true);
            }
        }).catch((error) => {
            console.log(error, '!!');
            toast.error(error?.response?.data?.message)
        })
    }

    const handleRefresh = () => {
        setDisable(true);
    }
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/project-store/dashboard">Dashboard </Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item active">Request List</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {disable === false ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card card-table show-entire">
                                    <div className="card-body">
                                        <div className="page-table-header mb-2">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="doctor-table-blk">
                                                        <h3>Request List</h3>
                                                        <div className="doctor-search-blk">
                                                            <div className="top-nav-search table-search-blk">
                                                                <form>
                                                                    <Search
                                                                        onSearch={(value) => {
                                                                            setSearch(value);
                                                                            setCurrentPage(1);
                                                                        }} />
                                                                    {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                                    <a className="btn"><img src="/assets/img/icons/search-normal.svg"
                                                                        alt="firm-searchBox" /></a>
                                                                </form>
                                                            </div>
                                                            <div className="add-group">
                                                                <button type='button' onClick={handleRefresh}
                                                                    className="btn btn-primary doctor-refresh ms-2" data-toggle="tooltip" data-placement="top" title="Refresh"><img
                                                                        src="/assets/img/icons/re-fresh.svg" alt="refresh" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pageDropDown col-auto text-end float-end ms-auto download-grp">
                                                    <DropDown limit={limit} onLimitChange={(val) => setlimit(val)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                            <table className="table border-0 custom-table comman-table  mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Sr.</th>
                                                        <th>Request No.</th>
                                                        <th>Material PO No.</th>
                                                        <th>Section Details</th>
                                                        <th>Unit</th>
                                                        <th>Request Qty.</th>
                                                        <th>Balance Qty.</th>
                                                        <th>Date</th>
                                                        <th>Offer Item</th>
                                                        <th>Store</th>
                                                        <th>Req. Status</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {commentsData?.map((elem, i) =>
                                                        <tr key={i}>
                                                            <td>{(currentPage - 1) * limit + i + 1}</td>
                                                            <td>{elem?.requestNo}</td>
                                                            <td>{elem?.material_po_no}</td>
                                                            <td>{elem?.itemName?.name}</td>
                                                            <td>{elem?.itemName?.unit?.name}</td>
                                                            <td>{elem?.quantity}</td>
                                                            <td>{elem?.balance_qty}</td>
                                                            <td>{moment(elem?.requestDate).format('YYYY-MM-DD')}</td>
                                                            <td>
                                                                {elem?.itemStatus === 1 ? (
                                                                    <span style={{ cursor: "pointer" }} onClick={() => handleReceive(elem)}>
                                                                        <HandCoins />
                                                                    </span>
                                                                ) :
                                                                    <span style={{ cursor: "pointer" }}> <X /></span>}
                                                            </td>
                                                            <td>
                                                                {elem?.store_type === 1 ? (
                                                                    <span className='custom-badge status-purple'>Main Store</span>
                                                                ) : (
                                                                    <span className='custom-badge status-purple'>Project Store</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <span className={`custom-badge ${elem.requestStatus === 2 ? 'status-blue' :
                                                                    elem.requestStatus === 4 ? 'status-green' : ''}`}>
                                                                    {elem.requestStatus === 2 ? 'Approved By Admin' :
                                                                        elem.requestStatus === 4 ? 'Completed' : ''}
                                                                </span>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="dropdown dropdown-action">
                                                                    <a href="#" className="action-icon dropdown-toggle"
                                                                        data-bs-toggle="dropdown" aria-expanded="false"><i
                                                                            className="fa fa-ellipsis-v"></i></a>
                                                                    <div className="dropdown-menu dropdown-menu-end">
                                                                        <button type='button' className="dropdown-item" onClick={() => navigate('/user/project-store/view-item-request', { state: elem })}>
                                                                            <i className="fa-solid fa-eye m-r-5"></i>
                                                                            View
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {commentsData?.length === 0 ? (
                                                        <tr>
                                                            <td colspan="999">
                                                                <div className="no-table-data">
                                                                    No Data Found!
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row align-center mt-3 mb-2">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                                                <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                                                    aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                    id="DataTables_Table_0_paginate">
                                                    <Pagination
                                                        total={totalItems}
                                                        itemsPerPage={limit}
                                                        currentPage={currentPage}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <Loader />}
                </div>
                <Footer />
            </div>
            <RequestModal
                show={show}
                handleClose={handleClose}
                selectedData={selectedData}
                stockData={stockData}
                handleSave={handleSave}
                authPerson={authPerson}
                partyData={partyData}
            />
        </div>
    )
}

export default Request