import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../../Pages/Users/Dashboard/Dashboard';
import Drawing from '../../Pages/Users/Planner/Drawing/Drawing';
import ViewDrawing from '../../Pages/Users/Planner/Drawing/ViewDrawing';
import ManageDrawing from '../../Pages/Users/Planner/Drawing/ManageDrawing';
import PurchaseRequest from '../../Pages/Users/Planner/Request/Purchase/PurchaseRequest';
import ManagePurchaseRequest from '../../Pages/Users/Planner/Request/Purchase/ManagePurchaseRequest';
import Stock from '../../Pages/Users/Stock/Stock';
import AdjustmentTable from '../../Pages/Users/Adjustment/AdjustmentTable';
import PurchaseOrder from '../../Pages/Users/Transaction/Purchase/PurchaseOrder';
import ManagePurchaseOrder from '../../Pages/Users/Transaction/Purchase/ManagePurchaseOrder';
import Request from '../../Pages/Users/Request/Request';
import ViewRequest from '../../Pages/Users/Request/ViewRequest';
import VerifyRequest from '../../Pages/Users/Qc/VerifyRequest/VerifyRequest';
import SalesOrder from '../../Pages/Users/Transaction/Sale/SalesOrder';
import ManageSaleOrder from '../../Pages/Users/Transaction/Sale/ManageSaleOrder';
import Purchase from '../../Pages/Users/Transaction/Purchase/Purchase';
import Item from '../../Pages/Users/Item/Item';
import ManageItem from '../../Pages/Users/Item/ManageItem';
import Party from '../../Pages/Users/Party/Party';
import ManageParty from '../../Pages/Users/Party/ManageParty';
import OfferList from '../../Pages/Users/MaterialCoordinator/OfferList';
import ViewOfferList from '../../Pages/Users/MaterialCoordinator/ViewOfferList';
import ApprovedItemList from '../../Pages/Users/MaterialCoordinator/ApprovedItemList';
import ManageIssue from '../../Pages/Users/MaterialCoordinator/ManageIssue';
import Category from '../../Pages/Users/StoreMaster/Category/Category';
import ManageCategory from '../../Pages/Users/StoreMaster/Category/ManageCategory';
import Unit from '../../Pages/Users/StoreMaster/Unit/Unit';
import ManageUnit from '../../Pages/Users/StoreMaster/Unit/ManageUnit';
import Transport from '../../Pages/Users/StoreMaster/Transport/Transport';
import ManageTransport from '../../Pages/Users/StoreMaster/Transport/ManageTransport';
import Location from '../../Pages/Users/StoreMaster/InventoryLocation/Location';
import ManageLocation from '../../Pages/Users/StoreMaster/InventoryLocation/ManageLocation';
import PartyGroup from '../../Pages/Users/StoreMaster/PartyGroup/PartyGroup';
import ManagePartyGroup from '../../Pages/Users/StoreMaster/PartyGroup/ManagePartyGroup';
import ViewQcRequest from '../../Pages/Users/Qc/VerifyRequest/ViewQcRequest';
import ViewApprovedList from '../../Pages/Users/MaterialCoordinator/ViewApprovedList';
import Profile from '../../Pages/Users/Profile/Profile';
import IssueList from '../../Pages/Users/Transaction/Issue/IssueList';
import StockReport from '../../Pages/Users/Report/Stock/StockReport';
import AuthList from '../../Pages/Users/StoreMaster/AuthPerson/AuthList';
import ManageAuth from '../../Pages/Users/StoreMaster/AuthPerson/ManageAuth';

const UsersRoute = () => {
    return (
        <>
            <Routes>
                <Route path='/user/project-store/dashboard' element={<Dashboard />} />
                <Route path='/user/project-store/edit-profile' element={<Profile />} />

                <Route path='/user/project-store/item-management' element={<Item />} />
                <Route path='/user/project-store/manage-item' element={<ManageItem />} />

                <Route path='/user/project-store/party-management' element={<Party />} />
                <Route path='/user/project-store/manage-party' element={<ManageParty />} />

                {/* Planner */}
                <Route path='/user/project-store/drawing-management' element={<Drawing />} />
                <Route path='/user/project-store/manage-drawing' element={<ManageDrawing />} />
                <Route path='/user/project-store/view-drawing' element={<ViewDrawing />} />


                <Route path='/user/project-store/material-request-management' element={<PurchaseRequest />} />
                <Route path='/user/project-store/manage-material-request' element={<ManagePurchaseRequest />} />

                {/* Product Store */}
                <Route path='/user/project-store/purchase-order-management' element={<PurchaseOrder />} />
                <Route path='/user/project-store/manage-purchase-order' element={<ManagePurchaseOrder />} />
                <Route path='/user/project-store/purchase-management' element={<Purchase />} />

                <Route path='/user/project-store/issue-management' element={<IssueList />} />

                <Route path='/user/project-store/sales-order-management' element={<SalesOrder />} />
                <Route path='/user/project-store/manage-sales-order' element={<ManageSaleOrder />} />

                <Route path='/user/project-store/item-request-management' element={<Request />} />
                <Route path='/user/project-store/view-item-request' element={<ViewRequest />} />

                <Route path='/user/project-store/item-records-management' element={<AdjustmentTable />} />
                <Route path='/user/project-store/stock-management' element={<Stock />} />

                {/* Material Cordinator */}
                <Route path='/user/project-store/offer-item-management' element={<OfferList />} />
                <Route path='/user/project-store/view-offered-item' element={<ViewOfferList />} />
                <Route path='/user/project-store/approved-item-management' element={<ApprovedItemList />} />
                <Route path='/user/project-store/view-approved-item' element={<ViewApprovedList />} />
                <Route path='/user/project-store/item-issue-management' element={<ManageIssue />} />

                {/* Masters */}
                <Route path='/user/project-store/category-management' element={<Category />} />
                <Route path='/user/project-store/manage-category' element={<ManageCategory />} />

                <Route path='/user/project-store/unit-management' element={<Unit />} />
                <Route path='/user/project-store/manage-unit' element={<ManageUnit />} />

                <Route path='/user/project-store/auth-person-management' element={<AuthList />} />
                <Route path='/user/project-store/manage-auth-person' element={<ManageAuth />} />

                <Route path='/user/project-store/transport-management' element={<Transport />} />
                <Route path='/user/project-store/manage-transport' element={<ManageTransport />} />

                <Route path='/user/project-store/inventory-location-management' element={<Location />} />
                <Route path='/user/project-store/manage-inventory-location' element={<ManageLocation />} />

                <Route path='/user/project-store/party-group-management' element={<PartyGroup />} />
                <Route path='/user/project-store/manage-party-group' element={<ManagePartyGroup />} />

                {/* QC */}
                <Route path='/user/project-store/verify-request-management' element={<VerifyRequest />} />
                <Route path='/user/project-store/view-qc-request' element={<ViewQcRequest />} />

                {/* Report */}
                <Route path='/user/project-store/stock-report' element={<StockReport />} />

                <Route path='/user/project-store/*' element={<Navigate to='/user/project-store/dashboard' />} />
            </Routes>
        </>
    )
}

export default UsersRoute