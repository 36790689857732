import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import toast from 'react-hot-toast';
import moment from 'moment';
import { V_URL } from '../../../BaseUrl';
import Footer from '../Include/Footer';
import Header from '../Include/Header';
import Sidebar from '../Include/Sidebar';
import Select from 'react-dropdown-select';
import productData from '../Include/ProductData.json';
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { getErpRole } from '../../../Store/Admin/ErpRole/ErpRole';

const ManageUser = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState({});
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const data = location.state;
    // console.log(data, '@@')

    const [user, setUser] = useState({
        user_name: "",
        email: "",
        password: "",
        year: "",
        product: "",
        pwdShow: true,
    });

    const [erpRole, setErpRole] = useState('');
    const [year, setYear] = useState([]);
    const [firm, setFirm] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [selectValue, setSelectValue] = useState('');
    const [selectedFirm, setSelectedFirm] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [userRole, setUserRole] = useState({ id: "", name: "" })
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedErpOptions, setSelectedErpOptions] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('VA_TOKEN') === null) {
            navigate("/admin/login");
        }
        getYear();
        getFirm();
        getRole();
        getProject();

        const fetchData = () => {
            try {
                dispatch(getErpRole())
            } catch (error) {
                console.log(error, '!!')
            }
        }
        fetchData();
    }, [navigate, dispatch]);

    const erpRoleData = useSelector((state) => state?.getErpRole?.user?.data);

    useEffect(() => {
        if (location.state) {
            setUser(prevUser => ({
                ...prevUser,
                user_name: location.state?.user_name,
                email: location.state?.email,
                year: location.state?.year?._id,
            }));

            setSelectValue(location.state?.status);
            setErpRole(location.state.erpRole?._id)

            setSelectedFirm(location.state?.firm?.map(e => ({ id: e?._id })) || []);
            setSelectedYear(location.state?.year?.map(e => ({ id: e?._id })) || []);
            setSelectedProject(location.state?.project?.map(e => ({ id: e?._id })) || []);

            setSelectedOptions(location.state?.product ? [{ name: location.state.product }] : []);
            setSelectedErpOptions(location.state.erpRole ? [{ name: location.state?.erpRole?.name, _id: location.state?.erpRole?._id }] : [])
        }

    }, [location.state]);

    const getYear = () => {
        const myurl = `${V_URL}/admin/get-year`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('VA_TOKEN') },
        }).then((response) => {
            if (response?.data?.success === true) {
                setYear(response?.data?.data);
            } else {
                toast.error("Something went wrong");
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
        });
    }

    const getFirm = () => {
        const myurl = `${V_URL}/admin/get-firm`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('VA_TOKEN') },
        }).then((response) => {
            if (response?.data?.success === true) {
                setFirm(response?.data?.data);
            } else {
                toast.error("Something went wrong");
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
        })
    }

    const getRole = () => {
        const myurl = `${V_URL}/admin/get-role`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('VA_TOKEN') },
        }).then((response) => {
            if (response?.data?.success === true) {
                setUserRole({
                    name: response?.data?.data[0].name,
                    id: response?.data?.data[0]._id,
                });
            } else {
                toast.error("Something went wrong");
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
        })
    }

    const getProject = () => {
        const myurl = `${V_URL}/admin/get-project`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('VA_TOKEN') },
        }).then((response) => {
            if (response?.data?.success === true) {
                setProjectData(response?.data?.data);
            } else {
                toast.error("Something went wrong");
            }
        }).catch((error) => {
            toast.error("Something went wrong");
            console?.log("Errors", error);
        });
    }

    const handleRadioChange = (event) => {
        setSelectValue(event.target.checked);
    };

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const handleSelect = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            setSelectedOptions(selectedOptions);
            setUser({ ...user, product: selectedOptions[0].name });
        } else {
            setUser({ ...user, product: '' });
        }
    }

    const handleErpSelect = (selectedErpOptions) => {
        if (selectedErpOptions.length > 0) {
            setSelectedErpOptions(selectedErpOptions);
            setErpRole(selectedErpOptions[0]?._id)
        } else {
            setErpRole('')
        }
    }

    const handleCheckboxChange = (event, elem) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedFirm(prevSelected => {
                const selectedArray = Array.isArray(prevSelected) ? prevSelected : [];
                return [...selectedArray, { id: elem._id }];
            });
        } else {
            setSelectedFirm(prevSelected => {
                const selectedArray = Array.isArray(prevSelected) ? prevSelected : [];
                return selectedArray.filter(item => item.id !== elem._id);
            });
        }
    }

    const handleYearCheckBox = (event, elem) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedYear(prevSelected => {
                const selectedArray = Array.isArray(prevSelected) ? prevSelected : [];
                return [...selectedArray, { id: elem._id }];
            });
        } else {
            setSelectedYear(prevSelected => {
                const selectedArray = Array.isArray(prevSelected) ? prevSelected : [];
                return selectedArray.filter(item => item.id !== elem._id);
            });
        }
    }

    const handleProjectCheckbox = (event, elem) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedProject(prevSelected => {
                const selectedArray = Array.isArray(prevSelected) ? prevSelected : [];
                return [...selectedArray, { id: elem._id }];
            });
        } else {
            setSelectedProject(prevSelected => {
                const selectedArray = Array.isArray(prevSelected) ? prevSelected : [];
                return selectedArray.filter(item => item.id !== elem._id);
            });
        }
    }

    const handleSubmit = () => {
        if (validation()) {
            setDisable(true);

            const bodyFormData = new URLSearchParams();
            bodyFormData.append('user_name', user?.user_name);
            bodyFormData.append('email', user.email);
            if (user?.password !== '') {
                bodyFormData.append('password', user.password);
            }
            bodyFormData.append('year', JSON.stringify(selectedYear));
            bodyFormData.append('firm', JSON.stringify(selectedFirm));
            bodyFormData.append('role', userRole.id);
            bodyFormData.append('product', user.product);
            bodyFormData.append('erpRole', erpRole);
            bodyFormData.append('project', JSON.stringify(selectedProject));

            if (data?._id) {
                bodyFormData.append('status', selectValue);
                bodyFormData.append('id', data?._id);
            }

            axios({
                method: 'post',
                url: `${V_URL}/admin/manage-user`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('VA_TOKEN') }
            }).then((response) => {
                if (response.data.success === true) {
                    toast.success(response.data.message);
                    navigate('/admin/user-management');
                    handleReset()
                    setDisable(false);
                }
            }).catch((error) => {
                console.log(error);
                toast.error(error.response?.data?.message || 'Something went wrong');
                setDisable(false);
            })
        }
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!user?.user_name || !user?.user_name?.trim()) {
            isValid = false;
            err['user_name_err'] = "Please enter user name"
        }
        if (!user.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        } else if (typeof user.email !== "undefined") {
            let lastAtPos = user.email.lastIndexOf('@');
            let lastDotPos = user.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && user.email.indexOf('@@') === -1 && lastDotPos > 2 && (user?.email?.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Email is not valid";
            }
        }
        if (!data?._id) {
            if (!user?.password) {
                isValid = false;
                err["password_err"] = "Please enter password";
            } else if (!/(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z]).{8,}/.test(user.password)) {
                isValid = false;
                err["password_err"] = "Password must contain at least one uppercase letter, one special character, and be at least 8 characters long";
            }
        }
        if (selectedOptions[0]?.name === 'Payroll System' && selectedFirm?.length === 0) {
            isValid = false;
            err["firm_err"] = "Please select atleast one firm";
            setOpen(true)
        } else {
            setOpen(false);
        }
        if (selectedOptions[0]?.name === 'Payroll System' && selectedYear?.length === 0) {
            isValid = false;
            err["year_err"] = "Please select atleast one year";
            setOpen2(true);
        } else {
            setOpen2(false);
        }
        if (selectedOptions.length === 0) {
            isValid = false;
            err["product_err"] = "Please select a product";
        }
        if (selectedOptions[0]?.name === 'Project Fabrication' && selectedErpOptions.length === 0) {
            isValid = false;
            err["erp_role_err"] = "Please select a project fabrication role";
        }
        if (user.product === 'Project Store' || user.product === 'Project Fabrication') {
            if (selectedProject?.length === 0) {
                isValid = false;
                err["project_err"] = "Please select atleast one project";
                setOpen3(true)
            }
        } else {
            setOpen3(false)
        }
        setError(err);
        return isValid
    }

    const handleReset = () => {
        setUser({
            user_name: "",
            email: "",
            password: "",
            year: "",
            product: "",
            pwdShow: true,
        });
        setSelectedFirm([]);
        setSelectedYear([]);
    }
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>

            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/admin/dashboard">Dashboard </Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item"><Link to="/admin/user-management">User List</Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} User</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="col-12">
                                            <div className="form-heading">
                                                <h4>{data?._id ? 'Edit' : 'Add'} User Details</h4>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label> Name <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text"
                                                        onChange={handleChange} value={user.user_name} name="user_name"
                                                    />
                                                    <div className='error'>{error.user_name_err}</div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label> Email <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="email"
                                                        onChange={handleChange} value={user.email} name="email"
                                                    />
                                                    <div className='error'>{error.email_err}</div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label> Password <span className="login-danger">*</span></label>
                                                    <input className="form-control" type={user.pwdShow ? "password" : "text"}
                                                        onChange={handleChange} value={user.password} name="password"
                                                    />
                                                    {user.pwdShow ? <div className="passwordHide" onClick={() => { setUser({ ...user, pwdShow: false }) }}><i className='fa-solid fa-eye' /></div> : <div className='passwordHide' onClick={() => setUser({ ...user, pwdShow: true })}><i className='fa-solid fa-eye-slash' /> </div>}
                                                    <div className='error'>{error.password_err}</div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label> Role <span className="login-danger">*</span></label>
                                                    <input className="form-control"
                                                        readOnly value={userRole.name}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label> Product <span className="login-danger">*</span></label>
                                                    <Select
                                                        name='product'
                                                        options={productData.data}
                                                        onChange={handleSelect}
                                                        labelField="name"
                                                        valueField="name"
                                                        color='#FF6700'
                                                        values={selectedOptions}
                                                    />
                                                    <div className='error'>{error?.product_err}</div>
                                                </div>
                                            </div>

                                            {selectedOptions[0]?.name === 'Project Fabrication' ? (
                                                <div className="col-12 col-md-4 col-xl-4">
                                                    <div className="input-block local-forms">
                                                        <label> Project Fabrication Role <span className="login-danger">*</span></label>
                                                        <Select
                                                            name='product'
                                                            options={erpRoleData}
                                                            onChange={handleErpSelect}
                                                            labelField="name"
                                                            valueField="_id"
                                                            color='#FF6700'
                                                            values={selectedErpOptions}
                                                        />
                                                        <div className='error'>{error?.erp_role_err}</div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {data?._id ? (
                                                <div className='col-12 col-md-4 col-xl-4'>
                                                    <div className="cardNum">
                                                        <div className="mb-3">
                                                            <label htmlFor="fileUpload" className="form-label">Status</label>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-xl-6">
                                                <div className="doctor-submit">
                                                    {user.product === 'Payroll System' ? (
                                                        <>
                                                            <button type="button"
                                                                className="btn btn-primary w-25"
                                                                onClick={() => setOpen(!open)}
                                                                aria-controls="example-collapse-text"
                                                                aria-expanded={open}
                                                            >Firm</button>
                                                            <button type="button"
                                                                className="btn btn-primary mx-2 w-25"
                                                                onClick={() => setOpen2(!open2)}
                                                                aria-controls="example-collapse-text"
                                                                aria-expanded={open2}
                                                            >Year</button>
                                                        </>
                                                    ) : null}
                                                    {user.product === 'Project Fabrication' || user.product === 'Project Store' ? (
                                                        <button type="button"
                                                            className="btn btn-primary mx-2 w-25"
                                                            onClick={() => setOpen3(!open3)}
                                                            aria-controls="example-collapse-text"
                                                            aria-expanded={open3}
                                                        >Project</button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="example-collapse-text">
                        <div className="row">
                            <Collapse in={open}>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Firm List
                                                                {selectedFirm?.length > 0 ? (
                                                                    <span className='custom-badge status-orange mx-2 adminUser'>{selectedFirm?.length}</span>
                                                                ) : null}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table border-0 custom-table comman-table  mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {firm?.map((elem, i) =>
                                                            <tr key={elem?._id}>
                                                                <td>
                                                                    <div className='d-flex firm-check gap-2 align-items-center'>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                onChange={(event) => handleCheckboxChange(event, elem)}
                                                                                checked={selectedFirm?.some(item => item.id === elem?._id)} />
                                                                        </div>
                                                                        <div>
                                                                            {elem?.name}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{elem?.email}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <div className='error mx-2'>{error.firm_err}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={open2}>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Year List
                                                                {selectedYear?.length > 0 ? (
                                                                    <span className='custom-badge status-orange mx-2 adminUser'>{selectedYear?.length}</span>
                                                                ) : null}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table border-0 custom-table comman-table  mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Year</th>
                                                            <th>Start Year</th>
                                                            <th>End Year</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {year?.map((elem, i) =>
                                                            <tr key={elem?._id}>
                                                                <td>
                                                                    <div className='d-flex firm-check gap-2 align-items-center'>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                onChange={(event) => handleYearCheckBox(event, elem)}
                                                                                checked={selectedYear?.some(item => item.id === elem?._id)} />
                                                                        </div>
                                                                        <div>
                                                                            {`${moment(elem?.start_year).format('YYYY')}-${moment(elem?.end_year).format('YYYY')}`}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{moment(elem?.start_year).format('YYYY-MM-DD')}</td>
                                                                <td>{moment(elem?.end_year).format('YYYY-MM-DD')}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <div className='error mx-2'>{error.year_err}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={open3}>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="card card-table show-entire">
                                        <div className="card-body">
                                            <div className="page-table-header mb-2">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <div className="doctor-table-blk">
                                                            <h3>Project List
                                                                {selectedProject?.length > 0 ? (
                                                                    <span className='custom-badge status-orange mx-2 adminUser'>{selectedProject?.length}</span>
                                                                ) : null}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table border-0 custom-table comman-table  mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Time Estimation</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {projectData?.map((elem, i) =>
                                                            <tr key={elem?._id}>
                                                                <td>
                                                                    <div className='d-flex firm-check gap-2 align-items-center'>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                onChange={(event) => handleProjectCheckbox(event, elem)}
                                                                                checked={selectedProject?.some(item => item.id === elem?._id)} />
                                                                        </div>
                                                                        <div>
                                                                            {elem?.name}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {(elem?.startDate !== null || elem?.endDate !== null) ? (
                                                                        <>
                                                                            {moment(elem?.startDate).format('YYYY-MM-DD')} / {moment(elem?.endDate).format('YYYY-MM-DD')}
                                                                        </>
                                                                    ) : '-'}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <div className='error mx-4'>{error.project_err}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-12">
                                        <div className="doctor-submit text-end">
                                            <button type="button"
                                                className="btn btn-primary submit-form me-2" onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                                            <button type="button"
                                                className="btn btn-primary cancel-form" onClick={handleReset}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div >
    )
}

export default ManageUser