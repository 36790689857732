import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../Pages/Store/Login/Login';
import Dashboard from '../Pages/Store/Dashboard/Dashboard';
import Unit from '../Pages/Store/StoreMaster/Unit/Unit';
import ManageUnit from '../Pages/Store/StoreMaster/Unit/ManageUnit';
import Category from '../Pages/Store/StoreMaster/Category/Category';
import ManageCategory from '../Pages/Store/StoreMaster/Category/ManageCategory';
import Transport from '../Pages/Store/StoreMaster/Transport/Transport';
import ManageTransport from '../Pages/Store/StoreMaster/Transport/ManageTransport';
import Location from '../Pages/Store/StoreMaster/InventoryLocation/Location';
import ManageLocation from '../Pages/Store/StoreMaster/InventoryLocation/ManageLocation';

import Project from '../Pages/Store/Project/Project';
import ManageProject from '../Pages/Store/Project/ManageProject';

import PartyGroup from '../Pages/Store/StoreMaster/PartyGroup/PartyGroup';
import ManagePartyGroup from '../Pages/Store/StoreMaster/PartyGroup/ManagePartyGroup';
import Stock from '../Pages/Store/Stock/Stock';
import ManageStock from '../Pages/Store/Stock/ManageStock';
import Party from '../Pages/Store/Party/Party';
import ManageParty from '../Pages/Store/Party/ManageParty';
import ForgetPassword from '../Pages/Store/Login/ForgetPassword';
import Otp from '../Pages/Store/Login/Otp';
import ResetPassword from '../Pages/Store/Login/ResetPassword';
import Item from '../Pages/Store/Item/Item';
import ManageItem from '../Pages/Store/Item/ManageItem';
// import Purchase from '../Pages/Store/Transaction/Purchase/Purchase';
// import Sales from '../Pages/Store/Transaction/Sale/Sales';
import SalesOrder from '../Pages/Store/Transaction/Sale/SalesOrder';
import ManageSaleOrder from '../Pages/Store/Transaction/Sale/ManageSaleOrder';
import PurchaseOrder from '../Pages/Store/Transaction/Purchase/PurchaseOrder';
import ManagePurchaseOrder from '../Pages/Store/Transaction/Purchase/ManagePurchaseOrder';
import SalesReturn from '../Pages/Store/Transaction/Sale/SalesReturn';
import AdjustmentTable from '../Pages/Store/Adjustment/AdjustmentTable';
import PurchaseReturn from '../Pages/Store/Transaction/Purchase/PurchaseReturn';



const Store = () => {

  return (
    <>
      <Routes>
        <Route path="/user/login" element={<Login />} />

        <Route path='/user/forget-password' element={<ForgetPassword />} />
        <Route path='/user/otp-verification' element={<Otp />} />
        <Route path='/user/reset-password' element={<ResetPassword />} />

        <Route path='/main-store/user/dashboard' element={<Dashboard />} />

        <Route path='/main-store/user/unit-management' element={<Unit />} />
        <Route path='/main-store/user/manage-unit' element={<ManageUnit />} />

        <Route path='/main-store/user/category-management' element={<Category />} />
        <Route path='/main-store/user/manage-category' element={<ManageCategory />} />

        <Route path='/main-store/user/transport-management' element={<Transport />} />
        <Route path='/main-store/user/manage-transport' element={<ManageTransport />} />

        <Route path='/main-store/user/inventory-location-management' element={<Location />} />
        <Route path='/main-store/user/manage-inventory-location' element={<ManageLocation />} />

        <Route path='/main-store/user/party-management' element={<Party />} />
        <Route path='/main-store/user/manage-party' element={<ManageParty />} />

        <Route path='/main-store/user/project-management' element={<Project />} />
        <Route path='/main-store/user/manage-project' element={<ManageProject />} />

        <Route path='/main-store/user/party-group-management' element={<PartyGroup />} />
        <Route path='/main-store/user/manage-party-group' element={<ManagePartyGroup />} />

        <Route path='/main-store/user/stock-management' element={<Stock />} />
        <Route path='/main-store/user/manage-stock' element={<ManageStock />} />

        <Route path='/main-store/user/item-management' element={<Item />} />
        <Route path='/main-store/user/manage-item' element={<ManageItem />} />

        <Route path='/main-store/user/purchase-order-management' element={<PurchaseOrder />} />
        <Route path='/main-store/user/manage-purchase-order' element={<ManagePurchaseOrder />} />
        <Route path='/main-store/user/purchase-return-management' element={<PurchaseReturn />} />

        <Route path='/main-store/user/sales-order-management' element={<SalesOrder />} />
        <Route path='/main-store/user/manage-sales-order' element={<ManageSaleOrder />} />
        <Route path='/main-store/user/sales-return-management' element={<SalesReturn />} />

        <Route path='/main-store/user/item-records-management' element={<AdjustmentTable />} />

        <Route path='/main-store/user/*' element={<Navigate to='/main-store/user/dashboard' />} />

      </Routes>
    </>
  )
}

export default Store