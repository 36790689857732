import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';

const DrawingModal = ({ show, handleClose, itemData, handleSaveModal, editData }) => {

    const [drawModal, setDrawModal] = useState({
        gridNo: '', itemNo: '', qty: '', itemName: '', length: '',
        width: '', itemWeight: '', assemblyWeight: '', assemblySurface: '', item_unit: '', _id: ""
    });
    const [error, setError] = useState({});

    useEffect(() => {
        if (editData) {
            setDrawModal({
                gridNo: editData?.grid_no,
                itemNo: editData?.item_no,
                qty: editData?.quantity,
                itemName: editData?.itemName?._id,
                length: editData?.item_length,
                width: editData?.item_width,
                itemWeight: editData?.item_weight,
                assemblyWeight: editData?.assembly_weight,
                assemblySurface: editData?.assembly_surface_area,
                item_unit: '',
                _id: editData?._id
            });
        }
    }, [editData]);

    useEffect(() => {
        const itemId = drawModal.itemName || editData?.itemName?._id;
        if (itemId) {
            const filterData = itemData?.find((it) => it?._id === itemId);
            setDrawModal((prevState) => ({
                ...prevState,
                item_unit: filterData?.unit?.name
            }));
        }
    }, [drawModal.itemName, editData?.itemName?._id, itemData]);

    const handleCloseModal = () => {
        setError({});
        handleClose();
    };

    const handleChange = (e) => {
        setDrawModal({ ...drawModal, [e.target.name]: e.target.value });
    }

    const handleClear = () => {
        setDrawModal({
            gridNo: '', itemNo: '', qty: '', itemName: '', length: '',
            width: '', itemWeight: '', assemblyWeight: '', assemblySurface: '', item_unit: '', _id: ""
        })
    }

    useEffect(() => {
        if (!show) {
            handleClear();
            setError({});
        }
    }, [show]);

    const handleSubmit = (add) => {
        if (validation()) {
            if (add === true) {
                handleSaveModal(drawModal, true);
                handleClear();
            } else {
                handleCloseModal();
                handleSaveModal(drawModal, false);
            }
        }
    }

    const validation = () => {
        let isValid = true;
        let err = {};
        if (!drawModal?.itemName) {
            isValid = false;
            err['itemName_err'] = 'Please select section detail';
        }
        if (!drawModal?.qty) {
            isValid = false;
            err['qty_err'] = 'Please enter quantity';
        }
        setError(err);
        return isValid;
    }

    return (
        <Modal show={show} onHide={handleCloseModal}
            size="lg"
            backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Section Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='material-section'>
                    <div className="row align-items-center mt-2">
                        <div className="col-12 col-md-2">
                            <label className="col-form-label">Section Details <span className="login-danger">*</span></label>
                        </div>
                        <div className="col-12 col-md-4">
                            <select className='form-control' value={drawModal.itemName}
                                onChange={handleChange} name='itemName'>
                                <option value=''>Select Section Details</option>
                                {itemData?.map((e) =>
                                    <option value={e?._id} key={e?._id}>{e?.name} ({e?.mcode})</option>
                                )}
                            </select>
                            <div className='error'>{error.itemName_err}</div>
                        </div>
                        {drawModal.item_unit && (
                            <>
                                <div className="col-12 col-md-2">
                                    <label className="col-form-label">Unit</label>
                                </div>
                                <div className="col-12 col-md-4">
                                    <input className="form-control" value={drawModal.item_unit} disabled />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="row align-items-center mt-2">
                        <div className='col-12 col-md-2'>
                            <label className="col-form-label">Grid No.</label>
                        </div>
                        <div className='col-12 col-md-4'>
                            <input className='form-control' type='text' onChange={handleChange}
                                name='gridNo' value={drawModal.gridNo} />
                        </div>
                        <div className='col-12 col-md-2 mt-2 mt-md-0'>
                            <label className="col-form-label">Item No.</label>
                        </div>
                        <div className='col-12 col-md-4 mt-2 mt-md-0'>
                            <input className='form-control' type='text' onChange={handleChange}
                                name='itemNo' value={drawModal.itemNo} />
                        </div>
                    </div>

                    <div className="row align-items-center mt-2">
                        <div className='col-12 col-md-2'>
                            <label className="col-form-label">Quantity(NOS) <span className="login-danger">*</span></label>
                        </div>
                        <div className='col-12 col-md-4'>
                            <input className='form-control' type='number' onChange={handleChange}
                                name='qty' value={drawModal.qty} />
                            <div className='error'>{error.qty_err}</div>
                        </div>
                        <div className='col-12 col-md-2 mt-2 mt-md-0'>
                            <label className="col-form-label">Length(mm)</label>
                        </div>
                        <div className='col-12 col-md-4 mt-2 mt-md-0'>
                            <input className='form-control' type='number' onChange={handleChange}
                                name='length' value={drawModal.length} />
                        </div>
                    </div>

                    <div className="row align-items-center mt-2">
                        <div className='col-12 col-md-2'>
                            <label className="col-form-label">Width(mm)</label>
                        </div>
                        <div className='col-12 col-md-4'>
                            <input className='form-control' type='number' onChange={handleChange}
                                name='width' value={drawModal.width} />
                        </div>
                        <div className='col-12 col-md-2 mt-2 mt-md-0'>
                            <label className="col-form-label">Item Weight(kg)</label>
                        </div>
                        <div className='col-12 col-md-4 mt-2 mt-md-0'>
                            <input className='form-control' type='number' onChange={handleChange}
                                name='itemWeight' value={drawModal.itemWeight} />
                        </div>
                    </div>

                    <div className="row align-items-center mt-2">
                        <div className='col-12 col-md-2'>
                            <label className="col-form-label">Assembly Weight(kg)</label>
                        </div>
                        <div className='col-12 col-md-4'>
                            <input className='form-control' type='number' onChange={handleChange}
                                name='assemblyWeight' value={drawModal.assemblyWeight} />
                        </div>
                        <div className='col-12 col-md-2 mt-2 mt-md-0'>
                            <label className="col-form-label">Assembly Surface Area(sqm)</label>
                        </div>
                        <div className='col-12 col-md-4 mt-2 mt-md-0'>
                            <input className='form-control' type='number' onChange={handleChange}
                                name='assemblySurface' value={drawModal.assemblySurface} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-primary' type='button' onClick={handleSubmit}>Save</button>
                <button className='btn btn-outline-primary m-2' type='button' onClick={() => handleSubmit(true)}>Add More</button>
            </Modal.Footer>
        </Modal>
    )
}

export default DrawingModal