import React from 'react'

const Footer = () => {
    return (
        <div className="footer dash_footer">
            {/* <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
                <p className="text-center ">Copyright © 2024 <a href="https://www.addonwebtech.com/"
                    target="_blank"> Addon Web Tech </a>Company All rights reserved.
                </p>
            </div>
        </div> */}
        </div>
    )
}

export default Footer