import React from 'react'
import Planner from './Planner'
import QcUser from './QcUser'
import MaterialController from './MaterialController'

const Index = () => {
    return (
        <>
            <Planner />
            <QcUser />
            <MaterialController />
        </>
    )
}

export default Index