import { configureStore } from '@reduxjs/toolkit'

// Admin
import adminLoginSlice from '../Store/Admin/Login/Login';
import adminForgetPasswordSlice from '../Store/Admin/Login/ForgetPassword';
import adminOtpSlice from '../Store/Admin/Login/Otp';
import resetAdminPasswordSlice from '../Store/Admin/Login/ResetPassword';

import getBankSlice from '../Store/Admin/Payroll/Bank/Bank';
import getAdminBankSlice from '../Store/Admin/Payroll/Bank/AdminBank';
import addBankSlice from '../Store/Admin/Payroll/Bank/ManageBank';

import getShiftSlice from '../Store/Admin/Payroll/Shift/Shift';
import getAdminShiftSlice from '../Store/Admin/Payroll/Shift/AdminShift'
import addShiftSlice from '../Store/Admin/Payroll/Shift/ManageShift';

import getGroupSlice from '../Store/Admin/Payroll/Group/getGroup';
import getAdminGroupSlice from '../Store/Admin/Payroll/Group/AdminGroup';
import addGroupSlice from '../Store/Admin/Payroll/Group/AdminGroup';

import getDesignationSlice from '../Store/Admin/Payroll/Designation/Designation';
import getAdminDesignationSlice from '../Store/Admin/Payroll/Designation/AdminDesgination';
import addDesignationSlice from '../Store/Admin/Payroll/Designation/ManageDesignation';

import getAuthPersonSlice from '../Store/Admin/Payroll/AuthPerson/AuthPerson';
import getAdminAuthSlice from '../Store/Admin/Payroll/AuthPerson/AdminAuthPerson';
import addAuthPersonSlice from '../Store/Admin/Payroll/AuthPerson/ManageAuthPerson';

import getSkillSlice from '../Store/Admin/Payroll/Skill/Skill';
import getAdminSkillSlice from '../Store/Admin/Payroll/Skill/AdminSkill';
import addSkillSlice from '../Store/Admin/Payroll/Skill/AddSkill';

import getEmployeeTypeSlice from '../Store/Admin/Payroll/EmployeeType/EmployeeType';
import getAdminEmployeeTypeSlice from '../Store/Admin/Payroll/EmployeeType/AdminEmployeeType';
import addEmployeeTypeSlice from '../Store/Admin/Payroll/EmployeeType/ManageEmployeeType';

import getDepartmentSlice from '../Store/Admin/Payroll/Department/Department';
import getAdminDepartmentSlice from '../Store/Admin/Payroll/Department/AdminDepartment';
import addDepartmentSlice from '../Store/Admin/Payroll/Department/ManageDepartment';

import getYearSlice from '../Store/Admin/Payroll/Year/Year';
import getAdminYearSlice from '../Store/Admin/Payroll/Year/AdminYear';
import addYearSlice from '../Store/Admin/Payroll/Year/ManageYear';

// admin Store
import getErpRoleSlice from '../Store/Admin/ErpRole/ErpRole';
import adminGetPartySlice from '../Store/Admin/Party/GetParty';
import adminGetEmployeeSlice from '../Store/Admin/Employee/Employee';
import getFirmSlice from '../Store/Admin/Firm/Firm';

import adminGetPartyTagSlice from '../Store/Admin/PartyTag/AdminPartyTag';
import adminGetPartyGroupSlice from '../Store/Admin/PartyTag/AdminPartyGroup';

import getAdminItemDataSlice from '../Store/Admin/Item/getAdminItem';


// Store ========================================================================

import userForgetPasswordSlice from '../Store/Store/Login/UserForget';
import userOtpSlice from '../Store/Store/Login/UserOtp';
import resetUserPasswordSlice from '../Store/Store/Login/resetPassword';

import getUnitSlice from '../Store/Store/StoreMaster/Unit/Unit';
import getAdminUnitSlice from '../Store/Store/StoreMaster/Unit/AdminUnit';
import addUnitSlice from '../Store/Store/StoreMaster/Unit/ManageUnit';

import getCategorySlice from '../Store/Store/StoreMaster/Category/Category';
import getAdminCategorySlice from '../Store/Store/StoreMaster/Category/AdminCategory';
import addCategorySlice from '../Store/Store/StoreMaster/Category/ManageCategory';

import getTransportSlice from '../Store/Store/StoreMaster/Transport/Transport';
import getAdminTransportSlice from '../Store/Store/StoreMaster/Transport/AdminTransport';
import addTransportSlice from '../Store/Store/StoreMaster/Transport/ManageTransport';

import getLocationSlice from '../Store/Store/StoreMaster/InventoryLocation/Location';
import getAdminLocationSlice from '../Store/Store/StoreMaster/InventoryLocation/AdminLocation';
import addLocationSlice from '../Store/Store/StoreMaster/InventoryLocation/ManageLocation';

import getPartySlice from '../Store/Store/Party/Party';
import getAdminPartySlice from '../Store/Store/Party/AdminParty';
import addPartySlice from '../Store/Store/Party/ManageParty';

import getPartyGroupSlice from '../Store/Store/StoreMaster/PartyGroup/PartyGroup';
import getAdminPartyGroupSlice from '../Store/Store/StoreMaster/PartyGroup/PartyAdminGroup';
import addPartyGroupSlice from '../Store/Store/StoreMaster/PartyGroup/ManageParty';

import getUserDepartmentSlice from '../Store/Store/StoreMaster/Department/Department';
import getUserEmployeeSlice from '../Store/Store/StoreMaster/Employee/Employee';

import getPartyTagSlice from '../Store/Store/StoreMaster/PartyTag/PartyTag';

import getStoreAuthPersonSlice from '../Store/Store/StoreMaster/AuthPerson/AuthPerson';
import getAdminStoreAuthSlice from '../Store/Store/StoreMaster/AuthPerson/AdminAuthPerson';
import addStoreAuthPersonSlice from '../Store/Store/StoreMaster/AuthPerson/ManageAuthPerson';

import getItemSlice from '../Store/Store/Item/Item';
import getAdminItemSlice from '../Store/Store/Item/AdminItem';
import addItemSlice from '../Store/Store/Item/ManageItem';

import getProjectSlice from '../Store/Store/Project/Project';
import getStoreEmployeeSlice from '../Store/Store/Employee/Employee';
import getAdjustmentSlice from '../Store/Store/Adjustment/getAdjustment';

import getItemStockSlice from '../Store/Store/Stock/getStock';
import getStoreDashboardSlice from '../Store/Store/Dashboard/Dashboard'

import getTransactionItemSlice from '../Store/Store/TransactionItem/TransactionItem';

import getOrderSlice from '../Store/Store/Order/Order';

//  ERp ================================================================================
// Planner -----------------------------------------------------

import getDrawingSlice from '../Store/Erp/Planner/Draw/Draw';
import getUserProfileSlice from '../Store/Store/Profile/Profile';
import getUserIssueSlice from '../Store/Store/Issue/Issue';
import getRequestSlice from '../Store/Store/Request/getRequest';    

import getUserDrawTrasactionSlice from '../Store/Store/TransactionItem/getDrawTransaction';

export default configureStore({
    reducer: {

        login: adminLoginSlice,
        forgetPassword: adminForgetPasswordSlice,
        adminOtp: adminOtpSlice,
        resetAdminPassword: resetAdminPasswordSlice,

        adminGetParty: adminGetPartySlice,
        adminGetEmployee: adminGetEmployeeSlice,

        getFirm: getFirmSlice,

        // Admin APIS=========================================================================
        //====== Payroll

        getBank: getBankSlice,
        getAdminBank: getAdminBankSlice,
        addBank: addBankSlice,

        getShift: getShiftSlice,
        getAdminShift: getAdminShiftSlice,
        addShift: addShiftSlice,

        getGroup: getGroupSlice,
        getAdminGroup: getAdminGroupSlice,
        addGroup: addGroupSlice,

        getDesignation: getDesignationSlice,
        getAdminDesignation: getAdminDesignationSlice,
        addDesignation: addDesignationSlice,

        getAuthPerson: getAuthPersonSlice,
        getAdminAuth: getAdminAuthSlice,
        addAuthPerson: addAuthPersonSlice,

        getAdminSkill: getAdminSkillSlice,
        getSkill: getSkillSlice,
        addSkill: addSkillSlice,

        getEmployeeType: getEmployeeTypeSlice,
        getAdminEmployeeType: getAdminEmployeeTypeSlice,
        addEmployeeType: addEmployeeTypeSlice,

        getDepartment: getDepartmentSlice,
        getAdminDepartment: getAdminDepartmentSlice,
        addDepartment: addDepartmentSlice,

        getYear: getYearSlice,
        getAdminYear: getAdminYearSlice,
        addYearSlice: addYearSlice,

        adminGetPartyTag: adminGetPartyTagSlice,
        adminGetPartyGroup: adminGetPartyGroupSlice,

        getErpRole: getErpRoleSlice,

        // Admin Store
        getAdminItemData: getAdminItemDataSlice,

        // Store =================================================

        userForgetPasswordSlice: userForgetPasswordSlice,
        userOtp: userOtpSlice,
        resetUserPassword: resetUserPasswordSlice,

        getUnit: getUnitSlice,
        getAdminUnit: getAdminUnitSlice,
        addUnit: addUnitSlice,

        getCategory: getCategorySlice,
        getAdminCategory: getAdminCategorySlice,
        addCategory: addCategorySlice,

        getTransport: getTransportSlice,
        getAdminTransport: getAdminTransportSlice,
        addTransport: addTransportSlice,

        getLocation: getLocationSlice,
        getAdminLocation: getAdminLocationSlice,
        addLocation: addLocationSlice,

        getParty: getPartySlice,
        getAdminParty: getAdminPartySlice,
        addParty: addPartySlice,

        getPartyGroup: getPartyGroupSlice,
        getAdminPartyGroup: getAdminPartyGroupSlice,
        addPartyGroup: addPartyGroupSlice,

        getUserDepartment: getUserDepartmentSlice,
        getUserEmployee: getUserEmployeeSlice,

        getPartyTag: getPartyTagSlice,

        getStoreAuthPerson: getStoreAuthPersonSlice,
        getAdminStoreAuth: getAdminStoreAuthSlice,
        addStoreAuthPerson: addStoreAuthPersonSlice,

        getItem: getItemSlice,
        getAdminItem: getAdminItemSlice,
        addItem: addItemSlice,

        getProject: getProjectSlice,
        getStoreEmployee: getStoreEmployeeSlice,

        getAdjustment: getAdjustmentSlice,

        getItemStock: getItemStockSlice,

        getStoreDashboard: getStoreDashboardSlice,

        getTransactionItem: getTransactionItemSlice,
        getOrder: getOrderSlice,

        // Erp =====================================================================
        //Planner ===============================

        getDrawing: getDrawingSlice,
        getUserProfile: getUserProfileSlice,

        getUserIssue: getUserIssueSlice,

        getRequest: getRequestSlice,
        getUserDrawTrasaction: getUserDrawTrasactionSlice,
    }
})