import { FileText, HardHat, LayoutDashboard, LayoutList, List, NotebookPen, Package2, PaintRoller, ShieldCheck, ShoppingCart, UserCheck, UserCog, Users, Warehouse, Wrench } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SidebarPath from "./SidebarPath";

const Sidebar = () => {
  const location = useLocation();

  const [storeMenu, setStoreMenu] = useState(false);
  const [planning, setPlanning] = useState(false);
  const [projectStore, setProjectStore] = useState(false);
  const [materialCheck, setMaterialCheck] = useState(false);
  const [report, setReport] = useState(false);
  const [qc, setQc] = useState(false);

  const toggleState = (setter, value) => () => setter(!value);

  const handlePlanner = toggleState(setPlanning, planning);
  const handleProjectStore = toggleState(setProjectStore, projectStore);
  const handleStore = toggleState(setStoreMenu, storeMenu);
  const handleQc = toggleState(setQc, qc);
  const handleMaterialCheck = toggleState(setMaterialCheck, materialCheck);
  const handleReport = toggleState(setReport, report);

  return (
    <div className="sidebar" id="sidebar">
      <SidebarPath
        location={location}
        setStoreMenu={setStoreMenu}
        setPlanning={setPlanning}
        setProjectStore={setProjectStore}
        setMaterialCheck={setMaterialCheck}
        setQc={setQc}
        setReport={setReport}
      />
      <div className="sidebar-inner slimscroll side-bar-scroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li>
              <Link
                to="/user/project-store/dashboard"
                className={`${location.pathname === "/user/project-store/dashboard" ? "active" : ""}`}>
                <span className="menu-side"><LayoutDashboard className="Dash-iCon" /> </span>
                <span>Dashboard</span>
              </Link>
            </li>

            <li className="submenu">
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <a className={`${storeMenu === true ? "subdrop active" : ""}`}
                onClick={handleStore} style={{ cursor: "pointer" }}>
                <span className="menu-side">
                  <Warehouse className="Dash-iCon" />
                </span>
                <span> Store Masters</span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: storeMenu ? "block" : "none" }}>
                <li>
                  <Link to="/user/project-store/unit-management" className={`${location.pathname === "/user/project-store/unit-management" ||
                    location.pathname === "/user/project-store/manage-unit" ? "active" : ""}`} >
                    Unit
                  </Link>

                  <Link to="/user/project-store/category-management"
                    className={`${location.pathname === "/user/project-store/category-management" ||
                      location.pathname === "/user/project-store/manage-category" ? "active" : ""}`}>
                    Item Category
                  </Link>

                  <Link to="/user/project-store/transport-management" className={`${location.pathname === "/user/project-store/transport-management" ||
                    location.pathname === "/user/project-store/manage-transport" ? "active" : ""}`}>
                    Transport
                  </Link>

                  <Link to="/user/project-store/inventory-location-management"
                    className={`${location.pathname === "/user/project-store/inventory-location-management" ||
                      location.pathname === "/user/project-store/manage-inventory-location" ? "active" : ""}`}>
                    Inventory Location
                  </Link>

                  <Link  to="/user/project-store/auth-person-management" className={`${location.pathname === "/user/project-store/auth-person-management" ||
                    location.pathname === "/user/project-store/manage-auth-person" ? "active" : ""}`}>
                      Auth Person
                    </Link>

                  {/* <Link to="/user/project-store/party-group-management"
                    className={`${location.pathname === "/user/project-store/party-group-management" ||
                      location.pathname === "/user/project-store/manage-party-group" ? "active" : ""}`}>
                    Party Group
                  </Link> */}
                </li>
              </ul>
            </li>

            <li>
              <Link to="/user/project-store/party-management"
                className={`${location.pathname === "/user/project-store/party-management" ||
                  location.pathname === "/user/project-store/manage-party" ? "active" : ""}`}>
                <span className="menu-side"><Users className="Dash-iCon" /> </span>
                <span>Party</span>
              </Link>
            </li>

            <li>
              <Link to="/user/project-store/item-management"
                className={`${location.pathname === "/user/project-store/item-management" ||
                  location.pathname === "/user/project-store/manage-item" ? "active" : ""}`}>
                <span className="menu-side"><LayoutList className="Dash-iCon" /> </span>
                <span>Section Details</span>
              </Link>
            </li>

            <li className="submenu">
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <a
                className={`${projectStore === true ? "subdrop active" : ""}`}
                style={{ cursor: "pointer" }}
                onClick={handleProjectStore}>
                <span className="menu-side">
                  <ShoppingCart className="Dash-iCon" />
                </span>
                <span> Project Store </span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: projectStore ? "block" : "none" }}>

                <Link to="/user/project-store/purchase-management"
                  className={`${location.pathname === '/user/project-store/purchase-management' ? 'active' : ''}`} >
                  Purchase
                </Link>

                <Link to="/user/project-store/purchase-order-management"
                  className={`${location.pathname === '/user/project-store/purchase-order-management' ||
                    location.pathname === '/user/project-store/manage-purchase-order' ? 'active' : ''}`} >
                  Purchase Order
                </Link>

                <Link to="" className={`${location.pathname === '' ? 'active' : ''}`} >
                  Purchase Return
                </Link>

                <Link to="/user/project-store/issue-management" className={`${location.pathname === '/user/project-store/issue-management' ? 'active' : ''}`} >
                  Issue
                </Link>

                <Link to="" className={`${location.pathname === '' ? 'active' : ''}`} >
                  Issue Return
                </Link>

                <Link to="" className={`${location.pathname === '' ? 'active' : ''}`} >
                  Sales
                </Link>

                <Link to="/user/project-store/sales-order-management"
                  className={`${location.pathname === '/user/project-store/sales-order-management' ||
                    location.pathname === '/user/project-store/manage-sales-order' ? 'active' : ''}`} >
                  Sales Order
                </Link>

                <Link to="/user/project-store/item-request-management"
                  className={`${location.pathname === '/user/project-store/item-request-management' ||
                    location.pathname === "/user/project-store/view-item-request" ? 'active' : ''}`} >
                  Request
                </Link>
              </ul>
            </li>

            <li className="submenu">
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <a className={`${planning === true ? "subdrop active" : ""}`}
                style={{ cursor: "pointer" }} onClick={handlePlanner}>
                <span className="menu-side">
                  <NotebookPen className="Dash-iCon" />
                </span>
                <span> Planning Eng. </span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: planning ? "block" : "none" }}>
                <Link to="/user/project-store/drawing-management" className={`${location.pathname === '/user/project-store/drawing-management' ||
                  location.pathname === '/user/project-store/manage-drawing' ? 'active' : ''}`} >
                  Drawing
                </Link>

                <Link to="/user/project-store/view-drawing" className={`${location.pathname === '/user/project-store/view-drawing' ? 'active' : ''}`} >
                  View Drawing
                </Link>
                <Link to="/user/project-store/material-request-management"
                  className={`${location.pathname === '/user/project-store/material-request-management' ||
                    location.pathname === '/user/project-store/manage-material-request' ? 'active' : ''}`} >
                  Raw Material Request
                </Link>
              </ul>
            </li>

            <li className="submenu">
              <a className={`${materialCheck === true ? "subdrop active" : ""}`}
                style={{ cursor: "pointer" }} onClick={handleMaterialCheck}>
                <span className="menu-side">
                  <UserCog className="Dash-iCon" />
                </span>
                <span> Material <br /> Coordinator </span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: materialCheck ? "block" : "none" }}>
                <Link to="/user/project-store/offer-item-management"
                  className={`${location.pathname === '/user/project-store/offer-item-management' ||
                    location.pathname === '/user/project-store/view-offered-item' ? 'active' : ''}`} >
                  Offered Request
                </Link>
                <Link to="/user/project-store/approved-item-management"
                  className={`${location.pathname === '/user/project-store/approved-item-management' ||
                    location.pathname === '/user/project-store/view-approved-item' ||
                    location.pathname === '/user/project-store/item-issue-management' ? 'active' : ''}`} >
                  Approved Request
                </Link>
              </ul>
            </li>

            <li className="submenu">
              <a className={`${qc === true ? "subdrop active" : ""}`}
                style={{ cursor: "pointer" }} onClick={handleQc}>
                <span className="menu-side">
                  <ShieldCheck className="Dash-iCon" />
                </span>
                <span> QC Eng.</span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: qc ? "block" : "none" }}>
                <Link to="/user/project-store/verify-request-management"
                  className={`${location.pathname === '/user/project-store/verify-request-management' ||
                    location.pathname === '/user/project-store/view-qc-request'
                    ? 'active' : ''}`} >
                  Verify Request
                </Link>
              </ul>
            </li>

            <li>
              <Link to="" className={`${location.pathname === "" ? "active" : ""}`}>
                <span className="menu-side"><UserCheck className="Dash-iCon" /> </span>
                <span>QA Eng.</span>
              </Link>
            </li>

            <li>
              <Link to="" className={`${location.pathname === "" ? "active" : ""}`}>
                <span className="menu-side"><HardHat className="Dash-iCon" /> </span>
                <span>Welding Eng.</span>
              </Link>
            </li>

            <li>
              <Link to="" className={`${location.pathname === "" ? "active" : ""}`}>
                <span className="menu-side"><Wrench className="Dash-iCon" /> </span>
                <span>Production Eng.</span>
              </Link>
            </li>

            <li>
              <Link to="" className={`${location.pathname === "" ? "active" : ""}`}>
                <span className="menu-side"> <PaintRoller className="Dash-iCon" /> </span>
                <span>Paint Eng.</span>
              </Link>
            </li>

            <li className="submenu">
              <a className={`${report === true ? "subdrop active" : ""}`}
                style={{ cursor: "pointer" }} onClick={handleReport}>
                <span className="menu-side">
                  <FileText className="Dash-iCon" />
                </span>
                <span> Report</span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: report ? "block" : "none" }}>
                <Link to="/user/project-store/stock-report"
                  className={`${location.pathname === '/user/project-store/stock-report'
                    ? 'active' : ''}`} >
                  Stock
                </Link>
              </ul>
            </li>

            <li>
              <Link to="/user/project-store/item-records-management" className={`${location.pathname === "/user/project-store/item-records-management" ? "active" : ""}`}>
                <span className="menu-side"><List className="Dash-iCon" /> </span>
                <span>Item Records</span>
              </Link>
            </li>

            <li>
              <Link to="/user/project-store/stock-management"
                className={`${location.pathname === "/user/project-store/stock-management" ? "active" : ""}`}>
                <span className="menu-side"><Package2 className="Dash-iCon" /> </span>
                <span>Stock</span>
              </Link>
            </li>
          </ul>
        </div>
      </div >
    </div >
  );
};

export default Sidebar;
