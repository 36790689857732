import { useEffect } from 'react';

const SidebarPath = ({ location, setStoreMenu, setPlanning, setProjectStore, setMaterialCheck, setQc, setReport }) => {
    useEffect(() => {
        const pathMappings = [
            {
                paths: [
                    '/user/project-store/category-management',
                    '/user/project-store/manage-category',
                    '/user/project-store/unit-management',
                    '/user/project-store/auth-person-management',
                    '/user/project-store/manage-unit',
                    '/user/project-store/transport-management',
                    '/user/project-store/manage-transport',
                    '/user/project-store/inventory-location-management',
                    '/user/project-store/manage-inventory-location',
                    '/user/project-store/party-group-management',
                    '/user/project-store/manage-party-group',
                    '/user/project-store/manage-auth-person'
                ],
                action: () => setStoreMenu(true),
            },
            {
                paths: [
                    '/user/project-store/drawing-management',
                    '/user/project-store/manage-drawing',
                    '/user/project-store/view-drawing',
                    '/user/project-store/material-request-management',
                    '/user/project-store/manage-material-request',
                ],
                action: () => setPlanning(true),
            },
            {
                paths: [
                    '/user/project-store/purchase-order-management',
                    '/user/project-store/manage-purchase-order',
                    '/user/project-store/purchase-management',
                    '/user/project-store/item-request-management',
                    '/user/project-store/view-item-request',
                    '/user/project-store/sales-order-management',
                    '/user/project-store/manage-sales-order',
                    '/user/project-store/issue-management',
                ],
                action: () => setProjectStore(true),
            },
            {
                paths: [
                    '/user/project-store/offer-item-management',
                    '/user/project-store/view-offered-item',
                    '/user/project-store/approved-item-management',
                    '/user/project-store/view-approved-item',
                    '/user/project-store/item-issue-management',
                ],
                action: () => setMaterialCheck(true),
            },
            {
                paths: [
                    '/user/project-store/verify-request-management',
                    '/user/project-store/view-qc-request',
                ],
                action: () => setQc(true),
            },
            {
                paths: ['/user/project-store/stock-report'],
                action: () => setReport(true),
            }
        ];

        pathMappings.forEach(({ paths, action }) => {
            if (paths.includes(location.pathname)) {
                action();
            }
        });
    }, [location.pathname, setStoreMenu, setPlanning, setProjectStore, setMaterialCheck, setQc, setReport]);

    return null;
}

export default SidebarPath