import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import axios from "axios";
import { V_URL } from "../../../BaseUrl";
import Header from "../Include/Header";
import Sidebar from "../Include/Sidebar";
import Footer from "../Include/Footer";
import { Link } from "react-router-dom";


const Dashboard = () => {

  const [data, setData] = useState({});

  useEffect(() => {
    const myurl = `${V_URL}/admin/storeDashboard`;
    axios({
      method: "get",
      url: myurl,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Barrer " + localStorage.getItem("VA_TOKEN"),
      },
    })
      .then((response) => setData(response?.data?.data))
      .catch((err) => console.log(err));

  }, []);

  // const { total_PurchaseOrder, total_SalesOrder, total_SalesReturn, total_PurchaseReturn } = data;
  // const series = [total_PurchaseOrder, total_PurchaseReturn, total_SalesOrder, total_SalesReturn];

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }



  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>

      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to='/admin/dashboard'>Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right"></i>
                  </li>
                  <li className="breadcrumb-item active">Admin Dashboard</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="good-morning-blk">
            <div className="row">
              <div className="col-md-6">
                <div className="morning-user">
                  <h2>
                    Good Morning, <span>{localStorage.getItem("VA_NAME")}</span>
                  </h2>
                  <p>Have a nice day at work</p>
                </div>
              </div>
              <div className="col-md-6 position-blk">
                <div className="morning-img">
                  <img src="/assets/img/morning-img-01.svg" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <img src="/assets/img/icons/calendar.svg" alt="calender-img" />
                  <img src="/assets/img/icons/profile-add.svg" alt="" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Departments</h4>
                  <h2>
                    <CountUp end={data?.total_Departments} />
                  </h2>
                  <p>
                    <span className="passive-view">
                      <i className="feather-arrow-up-right me-1"></i>20%
                    </span>
                    vs last month
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <img src="/assets/img/icons/profile-add.svg" alt="" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Stores</h4>
                  <h2>
                    <CountUp end={data?.total_ActiveInventries} />
                  </h2>
                  <p>
                    <span className="passive-view">
                      <i className="feather-arrow-up-right me-1"></i>20%{" "}
                    </span>{" "}
                    vs last month
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <img src="/assets/img/icons/profile-add.svg" alt="" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Users</h4>
                  <h2>
                    <CountUp end={data?.total_Users} />
                  </h2>
                  <p>
                    <span className="passive-view">
                      <i className="feather-arrow-up-right me-1"></i>20%{" "}
                    </span>{" "}
                    vs last month
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <img src="/assets/img/icons/profile-add.svg" alt="" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Employees</h4>
                  <h2>
                    <CountUp end={data?.total_employees} />
                  </h2>
                  <p>
                    <span className="passive-view">
                      <i className="feather-arrow-up-right me-1"></i>20%{" "}
                    </span>{" "}
                    vs last month
                  </p>
                </div>
              </div>
            </div>
          </div>


        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
