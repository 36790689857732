import { ArrowUpFromLine } from 'lucide-react';
import React, { useState } from 'react'
import ProcessLoader from '../../Pages/Users/Include/ProcessLoader';
import toast from 'react-hot-toast';
import axios from 'axios';

const UploadFile = ({ url }) => {
    const [disable, setDisable] = useState(false);

    const uploadFile = (e) => {
        const file = e?.target?.files[0];
        if (!file) {
            toast.error('Please select a file.');
            return;
        }
        const fileType = file.type;
        if (fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            toast.error('Only .xlsx files are allowed.');
            return;
        }

        setDisable(true);
        var bodyFormData = new FormData();
        bodyFormData.append("file", e?.target?.files[0]);
        axios({
            method: "post",
            url: url,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then((result) => {
            console.log("=====", result);
            if (result.data.success === true) {
                toast.success(result?.data?.message);
            } else {
                toast.error(result?.data?.message)
            }
            setDisable(false);
        }).catch((error) => {
            console.log(error);
            toast.error(error?.response?.data?.message);
            setDisable(false);
        })
    }

    return (
        <>
            <input
                type="file"
                onChange={uploadFile}
                disabled={disable}
                style={{ display: 'none' }}
                id="fileInput"
            />
            <label
                className="btn btn-primary ms-2 p-0 downLoadBtn"
                data-toggle="tooltip"
                data-placement="top"
                title="import"
                htmlFor="fileInput"
                disabled={disable}
            >
                {disable ? <ProcessLoader /> : <ArrowUpFromLine size={16} />}
            </label>
        </>
    )
}

export default UploadFile