
const PurchaseModal = ({ itemVal }) => {
    let isValid = true;
    let err = {};

    const validateField = (fieldName, errorMessage) => {
        if (!itemVal[fieldName]) {
            isValid = false;
            err[`${fieldName}_err`] = errorMessage;
        }
    };

    validateField('itemName', 'Please select an item');
    validateField('quantity', 'Please enter quantity');
    validateField('store_type', 'Please select store type');
    validateField('preffered_supplier', 'Please select preferred supplier');


    return { isValid, err };
}

export default PurchaseModal